import { useAtomValue, useSetAtom } from 'jotai';

import { onlineUserInfoAtom } from '@/store/user';

export function useOnlineUser() {
  return useAtomValue(onlineUserInfoAtom);
}

export function useSetOnlineUser() {
  return useSetAtom(onlineUserInfoAtom);
}
