import { PLUS_BACKEND, PLUS_FRONTEND, REBOOT } from '@/constants';
import { COURSE_LANDING_URL } from '@/constants/url';
import { RecruitingStatus } from '@/hooks/react-query/apply';
import { CourseType } from '@/types';

import { _99CLUB_COURSE_DETAIL, PLUS_AI } from '../course';

export interface GnbRootMenuGroup {
  type: 'rootGroup';
  title: string;
  children?: Array<GnbMenuGroup | GnbMenuGroupLink>;
  link?: string;
}

export interface GnbMenuGroup {
  type: 'group';
  title: string;
  children: GnbMenuSubGroup[] | GnbMenuItem[];
  isNew?: boolean;
}

export interface GnbMenuGroupLink {
  type: 'groupLink';
  title: string;
  link: string;
  newTab?: boolean;
  isNew?: boolean;
}

export interface GnbMenuSubGroup {
  type: 'subGroup';
  title: string;
  menus: GnbMenuItem[];
}

export interface GnbMenuItem {
  type: 'item';
  title: string;
  link: string;
  courseType?: CourseType;
  newTab?: boolean;
  isNew?: boolean;
  mobileHidden?: boolean;
}

type GnbMenu =
  | GnbRootMenuGroup
  | GnbMenuGroup
  | GnbMenuSubGroup
  | GnbMenuItem
  | GnbMenuGroupLink;

export const bootcampMenuGroups: Array<GnbMenuGroup | GnbMenuGroupLink> = [
  {
    type: 'group',
    title: '부트캠프',
    children: [
      {
        type: 'subGroup',
        title: '첫 취업',
        menus: [
          {
            type: 'item',
            link: COURSE_LANDING_URL.HANGHAE_JOB_SUPPORT,
            title: '취업 리부트 코스',
            courseType: REBOOT,
          },
        ],
      },
      {
        type: 'subGroup',
        title: '역량강화/이직',
        menus: [
          {
            type: 'item',
            link: 'https://hhplus-ai.oopy.io/',
            title: '플러스 AI',
            courseType: PLUS_AI,
            newTab: true,
          },
          {
            type: 'item',
            link: COURSE_LANDING_URL.HANGHAE_PLUS_BACKEND,
            title: '플러스 백엔드',
            courseType: PLUS_BACKEND,
          },
          {
            type: 'item',
            link: COURSE_LANDING_URL.HANGHAE_PLUS_FRONTEND,
            title: '플러스 프론트엔드',
            courseType: PLUS_FRONTEND,
          },
        ],
      },
    ],
  },
  {
    type: 'group',
    title: '스터디',
    children: [
      {
        type: 'item',
        title: '코딩 테스트 스터디',
        link: COURSE_LANDING_URL['99CLUB_CODING_TEST'],
        courseType: _99CLUB_COURSE_DETAIL['coding-test'].type,
      },
      {
        type: 'item',
        title: 'Docker 스터디',
        link: 'https://99club-dockercicd.oopy.io/',
        // @ts-ignore
        courseType: '99CLUB_DOCKER_STUDY',
        newTab: true,
      },
    ],
  },
  {
    type: 'groupLink',
    title: '후기&스토리',
    link: '/blog',
    newTab: true,
  },
  {
    type: 'groupLink',
    title: '웨비나',
    link: '/webinar',
    newTab: false,
  },
];

export const studyMenuGroups: Array<GnbMenuGroup | GnbMenuGroupLink> = [
  {
    type: 'group',
    title: '스터디',
    children: [
      {
        type: 'subGroup',
        title: '',
        menus: [
          {
            type: 'item',
            link: COURSE_LANDING_URL['99CLUB_CODING_TEST'],
            title: '코딩 테스트 스터디',
            courseType: '99CLUB_CODING_TEST',
          },
          {
            type: 'item',
            link: 'https://99club-dockercicd.oopy.io/',
            title: 'Docker 스터디',
            // @ts-ignore
            courseType: '99CLUB_DOCKER_STUDY',
            newTab: true,
          },
        ],
      },
    ],
  },
];

export const GnbMenuList: GnbMenu[] = [
  {
    type: 'rootGroup',
    title: '부트캠프',
    children: bootcampMenuGroups,
  },
  {
    type: 'rootGroup',
    title: '스터디',
    children: studyMenuGroups,
  },
  { type: 'item', title: '웨비나', link: '/webinar' },
  { type: 'item', title: '후기&스토리', link: '/blog', newTab: true },
];

export const MobileGnbMenuList: GnbMenu[] = [
  { type: 'item', title: '나의 수강', link: '/users/my-page?tab=나의 수강' },
  { type: 'item', title: '결제 내역', link: '/users/my-page?tab=결제 내역' },
  { type: 'item', title: '계정 정보', link: '/users/my-page?tab=계정 정보' },
];

export function isGroup(item: GnbMenu): item is GnbMenuGroup {
  return item.type === 'group';
}

export function isSubGroup(item: GnbMenu): item is GnbMenuSubGroup {
  return item.type === 'subGroup';
}

export function isGroupLink(item: GnbMenu): item is GnbMenuGroupLink {
  return item.type === 'groupLink';
}

export function isItem(item: GnbMenu): item is GnbMenuItem {
  return item.type === 'item';
}

export const BADGE_TEXT_MAP: Record<RecruitingStatus, string> = {
  LOADING: '',
  RECRUITING: '모집 중',
  ALIM: '알림신청',
};
