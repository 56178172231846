import { instance } from '@/api/v2/instance';
import { GetAllRoundsResponseDto } from '@/services/common/round/round.dto';
import { Course } from '@/types';

import {
  GetEnrollableRoundResponseDto,
  GetNotifiableRoundResponseDto,
  GetPayableRoundResponseDto,
} from './round.dto';

export async function getEnrollableRound(courseName: string) {
  try {
    const { data } = await instance.get<GetEnrollableRoundResponseDto>(
      '/v3/rounds/enrollable',
      {
        params: {
          courseName,
        },
      },
    );

    if (!data) {
      return null;
    }

    return data;
  } catch (error) {
    return null;
  }
}

export async function getPayableRound(courseName: Course) {
  try {
    const { data } = await instance.get<GetPayableRoundResponseDto>(
      '/v3/rounds/payable',
      {
        params: {
          courseName,
        },
      },
    );
    return data.data;
  } catch (error) {
    return null;
  }
}

export async function getNotifiableRoundByCourseName(courseName: Course) {
  const { data } = await instance.get<GetNotifiableRoundResponseDto>(
    '/v3/rounds/notifiable',
    {
      params: {
        courseName,
      },
    },
  );

  return data;
}

export async function getUrgentRound(courseName: string) {
  try {
    const { data } = await instance.get<GetEnrollableRoundResponseDto>(
      '/v3/rounds/urgent',
      {
        params: {
          courseName,
        },
      },
    );
    return data;
  } catch (error) {
    return null;
  }
}

export async function getAllRoundsByCourseName(courseName: Course) {
  const { data } = await instance.get<GetAllRoundsResponseDto>(`/v3/rounds`, {
    params: {
      courseName,
    },
  });

  return data;
}
