/**
 * @todo 여기에 사용된 상수들, 함수들, 타입들을 어떻게 분리할지, 여기에 그대로 둘지 추후 시간이 흘러 조금 더 프로젝트가 성장하고 각자 더 나은 의견이 나오면 기준을 정할 필요가 있음.
 * @link https://github.com/TeamSparta-Inc/hh-v2-frontend/pull/1256
 */

import {
  _99ClubCourseDetail,
  _99ClubCourseRoute,
  CourseDetail,
  CourseRoute,
  OldCourse,
  OldCourseDetail,
  OldCourseRoute,
  PlusCourseDetail,
  PlusCourseRoute,
  RebootCourseDetail,
  RebootCourseRoute,
} from '@/types';

export const FULL_TIME = 'HANGHAE';
export const PLUS_BACKEND = 'HANGHAE_PLUS_BACKEND';
export const PLUS_FRONTEND = 'HANGHAE_PLUS_FRONTEND';
export const PLUS_AI = 'HANGHAE_PLUS_AI';
export const REBOOT = 'HANGHAE_JOB_SUPPORT';
export const INNOVATION = 'INNOVATION';

// DETAIL 이름?
export const PLUS_COURSE_DETAIL: Record<PlusCourseRoute, PlusCourseDetail> = {
  fe: {
    type: PLUS_FRONTEND,
    koreanName: '플러스 프론트엔드 코스',
    koreanPosition: '프론트엔드',
    englishPosition: 'frontend',
    landingUrl: '/plus/fe',
    applyUrl: '/plus/apply/fe',
  },
  be: {
    type: PLUS_BACKEND,
    koreanName: '플러스 백엔드 코스',
    koreanPosition: '백엔드',
    englishPosition: 'backend',
    landingUrl: '/plus/be',
    applyUrl: '/plus/apply/be',
  },
  ai: {
    type: PLUS_AI,
    koreanName: '플러스 AI 코스',
    koreanPosition: 'AI',
    englishPosition: 'ai',
    landingUrl: '/plus/ai',
    applyUrl: '/plus/apply/ai',
  },
};

export const PLUS_CORUSE_KOREAN_NAMES = Object.values(PLUS_COURSE_DETAIL).map(
  (course) => course.koreanName,
);

export const _99CLUB_COURSE_DETAIL: Record<
  _99ClubCourseRoute,
  _99ClubCourseDetail
> = {
  'coding-test': {
    type: '99CLUB_CODING_TEST',
    koreanName: '99클럽 코딩테스트 스터디',
    applyUrl: '/99club/apply/coding-test',
    landingUrl: '/99club-codingtest',
  },
};

export const REBOOT_COURSE_DETAIL: Record<
  RebootCourseRoute,
  RebootCourseDetail
> = {
  reboot: {
    type: 'reboot',
    koreanName: '취업 리부트 코스',
    applyUrl: '/reboot/apply',
    landingUrl: '/reboot',
  },
};

export const OLD_COURSE_DETAIL: Record<OldCourseRoute, OldCourseDetail> = {
  'full-time': {
    type: 'full-time',
    koreanName: '항해 풀타임',
  },
  'part-time': {
    type: 'part-time',
    koreanName: '항해 파트타임',
  },
  pm: {
    type: 'pm',
    koreanName: '항해PM코스',
  },
  pre: {
    type: 'pre',
    koreanName: '항해 프리코스',
  },
};

// DETAIL 이름?
export const COURSE_DETAIL: Record<CourseRoute, CourseDetail> = {
  ...PLUS_COURSE_DETAIL,
  ..._99CLUB_COURSE_DETAIL,
  reboot: {
    type: REBOOT,
    koreanName: '취업 리부트 코스',
    applyUrl: '/reboot/apply',
  },
  innovation: {
    type: INNOVATION,
    koreanName: '24이노캠',
  },
};

type ValueOf<T> = T[keyof T];

type CourseKey = keyof typeof COURSE_DETAIL;
type CourseDetailValue = ValueOf<(typeof COURSE_DETAIL)[CourseKey]>;

export function getCourseDetail(
  value: CourseDetailValue | CourseKey,
): CourseDetail | undefined {
  if (COURSE_DETAIL[value]) {
    return COURSE_DETAIL[value];
  }

  return Object.values(COURSE_DETAIL).find((course) =>
    Object.values(course).includes(value as CourseDetailValue),
  );
}

export function isOldCourse(course: string): course is OldCourse {
  return Object.values(OLD_COURSE_DETAIL).some(
    (oldCourse) => oldCourse.koreanName === course,
  );
}

export function isPlusBE(value: CourseDetailValue | CourseKey) {
  return getCourseDetail(value)?.koreanName === '플러스 백엔드 코스';
}

export function isPlusFE(value: CourseDetailValue | CourseKey) {
  return getCourseDetail(value)?.koreanName === '플러스 프론트엔드 코스';
}

export function isPlusAI(value: CourseDetailValue | CourseKey) {
  return getCourseDetail(value)?.koreanName === '플러스 AI 코스';
}

export function isPlus(value: CourseDetailValue | CourseKey) {
  return Object.values(PLUS_COURSE_DETAIL).some(
    (plusCourse) =>
      plusCourse.koreanName === getCourseDetail(value)?.koreanName,
  );
}

export function is99Club(value: CourseDetailValue | CourseKey) {
  return Object.values(_99CLUB_COURSE_DETAIL).some(
    (_99ClubCourse) =>
      _99ClubCourse.koreanName === getCourseDetail(value)?.koreanName,
  );
}

export function isReboot(value: CourseDetailValue | CourseKey) {
  return getCourseDetail(value)?.koreanName === '취업 리부트 코스';
}
