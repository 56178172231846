import 'styles/globals.css';
import 'styles/nprogress.css';

import { ErrorBoundary } from '@sentry/nextjs';
import { neutralDay } from '@teamsparta/design-system';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import { ReactElement, ReactNode } from 'react';
import { Hydrate } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Layout from '@/components/layout/Layout';
import { Providers } from '@/components/providers';
import { usePreviousPath } from '@/hooks/common/usePreviousPath';
import ErrorPageTemplate from '@/pages/error/template';
import DesignSystemCss from '@/styles/DesignSystemCss';
import { fontFace } from '@/styles/fonts';
import {
  ChannelScript,
  GoogleTagManagerBodyNoscript,
  GoogleTagManagerHeadScript,
  IamportScript,
  initAmplitude,
  initCPL,
  KakaoPageview,
} from '@/utils/sdk';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

initCPL();
initAmplitude();

NProgress.configure({
  template: '<div class="bar" role="bar"><div class="peg"></div></div>',
});

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  let { url } = router.query;

  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  usePreviousPath();

  return (
    <Providers>
      <Script
        id='kakao-js'
        defer={true}
        src='/js/kakao-dev.js'
        onLoad={() => {
          window.Kakao.init('535068688f1a8bca1c21a9445ede0a89');
        }}
      />
      <Head>
        <title>개발자 커리어 개척 캠프 항해99</title>
        <link rel='icon' href='/favicon.ico' />
        <meta name='theme-color' content={neutralDay.gray100} />
        <GoogleTagManagerHeadScript />
        <IamportScript />
        <link
          rel='preconnect'
          href='https://developers.kakao.com'
          crossOrigin='true'
        />
        <link
          rel='preconnect'
          href='https://t1.daumcdn.net'
          crossOrigin='true'
        />
        <link
          rel='preconnect'
          href='https://cdn.channel.io'
          crossOrigin='true'
        />
        <style>{fontFace}</style>
      </Head>
      <GoogleTagManagerBodyNoscript />
      {url && !JSON.parse(process.env.IS_DEV) && (
        <KakaoPageview url={url.toString()} />
      )}
      <ErrorBoundary
        fallback={(errorData) => (
          <ErrorPageTemplate statusCode={500} {...errorData} />
        )}
      >
        <Hydrate state={pageProps.dehydratedState}>
          {getLayout(<Component {...pageProps} />)}
        </Hydrate>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
      <ChannelScript />
      <DesignSystemCss />
    </Providers>
  );
}

export default MyApp;
