import {
  DesignSystemProvider,
  hanghae,
  neutralDay,
  Toaster,
} from '@teamsparta/design-system';
import { Provider as JotaiProvider } from 'jotai';
import { ReactNode, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { OnlineUserInfoProvider } from '@/components/providers/OnlineUserInfoProvider';
import { Hackle } from '@/utils/sdk';

const breakpoints = {
  desktop: 1024,
  mobile: 0,
};

const theme = {
  mode: 'light',
  colors: {
    light: {
      primary: hanghae.red100,
      secondary: neutralDay.gray100,
      tertiary: neutralDay.gray10,
    },
    dark: {
      primary: hanghae.red100,
      secondary: neutralDay.gray10,
      tertiary: neutralDay.gray90,
    },
  },
} as const;

export function Providers({ children }: { children: ReactNode }) {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <DesignSystemProvider breakpoints={breakpoints} theme={theme}>
      <QueryClientProvider client={queryClient}>
        <JotaiProvider>
          <OnlineUserInfoProvider>
            <Hackle>{children}</Hackle>
            <Toaster />
          </OnlineUserInfoProvider>
        </JotaiProvider>
      </QueryClientProvider>
    </DesignSystemProvider>
  );
}
