import { Flex, Text } from '@teamsparta/design-system';
import { useAtom, useSetAtom } from 'jotai';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useRef, useState } from 'react';
import MobileGnb from 'src/components/molecules/MobileGNB';

import logo from '/public/images/logo/logo.png';
import { useScrollDirection } from '@/utils/businessLogics/_common/useScrollDirection';
import { LoggingClick } from '@/components/atoms/Common/LoggingClick';
import SwitchCase from '@/components/atoms/Common/SwitchCase';
import { is99Club } from '@/constants';
import {
  BADGE_TEXT_MAP,
  GnbMenuGroup,
  GnbMenuItem,
  GnbMenuList,
  GnbMenuSubGroup,
  GnbRootMenuGroup,
  isGroup,
  isSubGroup,
} from '@/constants/gnb';
import { useOnlineUser } from '@/hooks';
import { useBoolean, useHover } from '@/hooks/common';
import { useRecruitingStatusByCourse } from '@/hooks/react-query/apply';
import { isAlreadyPopupAtom, MobileGNBOpenAtom } from '@/store/landing';
import { useLoginModal } from '@/store/login';
import { isClient } from '@/utils/helpers/enviroment';

import ArrowUpDown from '../ArrowUpDown/ArrowUpDown';
import UserGnbButton from '../UserGnbButton';
import * as S from './GNB.style';
import { getIsActivatedGroup } from './logic';

interface GnbProps {
  fixed?: boolean;
  scrollY?: number;
  ssrOnlineUserId?: string;
}

export default function Gnb({ fixed = false, scrollY }: GnbProps) {
  const router = useRouter();
  const setIsAlreadyPopup = useSetAtom(isAlreadyPopupAtom);
  const [isOpen, setIsOpen] = useAtom(MobileGNBOpenAtom);
  const userInfo = useOnlineUser();
  const loginModal = useLoginModal();
  const direction = useScrollDirection();

  const isSignIn = !!userInfo;

  function handleClickLogin() {
    if (isSignIn) return;
    loginModal.open(router.asPath);
  }

  useEffect(() => {
    function handleRouteChange() {
      setIsOpen(false);
      setIsAlreadyPopup(false);
    }
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, setIsAlreadyPopup, setIsOpen]);

  return (
    <S.Container scrollY={scrollY} isMobileGnbOpen={isOpen}>
      <S.Wrapper fixed={fixed} scrollDirection={direction}>
        <S.ImageAndNavBtn fixed={fixed} scrollDirection={direction}>
          <Link href='/' passHref>
            <S.ImageWrapper>
              <Image src={logo} layout='fill' objectFit='contain' alt='logo' />
            </S.ImageWrapper>
          </Link>

          <S.PageBtnList>
            <S.PageBtnEachSide>
              {GnbMenuList.map((menu) => (
                <SwitchCase
                  key={menu.title}
                  cases={menu.type}
                  caseBy={{
                    rootGroup: <GnbRootGroup data={menu as GnbRootMenuGroup} />,
                    item: <GnbListLinkItem data={menu as GnbMenuItem} />,
                  }}
                />
              ))}
            </S.PageBtnEachSide>

            <S.PageBtnEachSide>
              {userInfo?._id ? (
                <UserGnbButton />
              ) : (
                <S.LogoutBtn
                  scrollDirection={fixed ? 'up' : direction}
                  onClick={handleClickLogin}
                >
                  로그인
                </S.LogoutBtn>
              )}
            </S.PageBtnEachSide>
          </S.PageBtnList>
        </S.ImageAndNavBtn>
      </S.Wrapper>
      <MobileGnb />
    </S.Container>
  );
}

interface GnbRootGroupProps {
  data: GnbRootMenuGroup;
}

function GnbRootGroup({ data }: GnbRootGroupProps) {
  const { title, children } = data;
  const router = useRouter();
  const timerIdRef = useRef(null);
  const [isOpen, , setOpen, setClose] = useBoolean(false);
  const { isHovered, ...hoverProps } = useHover<HTMLDivElement>({
    onHoverStart: () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
      setOpen();
    },
    onHoverEnd: () => {
      timerIdRef.current = setTimeout(() => {
        setClose();
      }, 100);
    },
  });
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const isActiveGroup = getIsActivatedGroup(
    children,
    (item) => item.link === router.asPath,
  );

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  return (
    <S.GnbRootGroupContainer>
      <S.StyledLink isClicked={isHovered || isActiveGroup} {...hoverProps}>
        {title}
        <ArrowUpDown show={isOpen} color='#B4BFC6' />
      </S.StyledLink>
      <S.GnbGroupExpandOverlay isOpen={isOpen} />
      <S.GnbGroupExpandOverlayContentWrapper
        {...hoverProps}
        isOpen={isOpen}
        contentHeight={contentHeight}
        ref={contentRef}
      >
        <S.GnbGroupExpandOverlayContent>
          {children.map(
            (menu) =>
              isGroup(menu) && (
                <Fragment key={menu.title}>
                  <GnbGroup data={menu} />
                </Fragment>
              ),
          )}
        </S.GnbGroupExpandOverlayContent>
      </S.GnbGroupExpandOverlayContentWrapper>
    </S.GnbRootGroupContainer>
  );
}

interface GnbGroupProps {
  data: GnbMenuGroup;
}

function GnbGroup({ data }: GnbGroupProps) {
  const { children } = data;

  return (
    <S.GnbGroupContainer>
      {children.map(
        (submenu) =>
          isSubGroup(submenu) && (
            <GnbSubGroup key={submenu.title} {...submenu} />
          ),
      )}
    </S.GnbGroupContainer>
  );
}

interface GnbListLinkProps {
  data: GnbMenuItem;
}

function GnbListLinkItem({ data }: GnbListLinkProps) {
  const { link, title, newTab, isNew } = data;
  const router = useRouter();

  const isActive = router.asPath.includes(link);

  return (
    <LoggingClick
      logName='hh_gnb_click'
      data={{
        button_text: title,
        button_href: link,
        page_title: isClient() ? document.title : '',
      }}
    >
      <S.PageBtnListItem>
        <Link href={link} target={newTab ? '_blank' : ''} passHref>
          <S.StyledLink isClicked={isActive}>{title}</S.StyledLink>
        </Link>
        {isNew && <S.RedDot />}
      </S.PageBtnListItem>
    </LoggingClick>
  );
}

interface GnbSubGroupProps extends GnbMenuSubGroup {}

function GnbSubGroup({ title, menus }: GnbSubGroupProps) {
  return (
    <Flex.Column gap={12}>
      {title && (
        <Text
          as='span'
          mFont='wCaption1'
          wFont='wCaption1'
          color='neutralDay.gray60'
        >
          {title}
        </Text>
      )}
      {menus.map((item) => (
        <GnbSubGroupItem key={item.title} {...item} />
      ))}
    </Flex.Column>
  );
}

interface GnbSubGroupItemProps extends GnbMenuItem {}

function GnbSubGroupItem({
  link,
  title,
  courseType,
  newTab,
}: GnbSubGroupItemProps) {
  const router = useRouter();
  const { status } = useRecruitingStatusByCourse({
    course: courseType,
    enabled: !is99Club(courseType),
  });
  const isActive = router.asPath.includes(link);
  const isDisabled = false;

  const badgeText = BADGE_TEXT_MAP[status];

  return (
    <LoggingClick
      logName='hh_gnb_click'
      data={{
        button_text: title,
        button_href: link,
        page_title: isClient() ? document.title : '',
      }}
    >
      <Flex.Row align='center' gap={8}>
        <Link
          key={title}
          href={isDisabled ? '' : link}
          target={newTab ? '_blank' : ''}
          passHref
        >
          {title && (
            <S.GnbSubGroupItem
              as='span'
              mFont='mBody2'
              wFont={isActive ? 'wBody1' : 'wBody2'}
              color={isActive ? 'hanghae.red100' : 'neutralDay.gray0'}
              isActive={isActive}
              disabled={isDisabled}
            >
              {title}
            </S.GnbSubGroupItem>
          )}
        </Link>
        <S.RoundInfoBadge
          isNew
          badgeText={
            is99Club(courseType)
              ? '모집 중'
              : // @ts-ignore
              courseType === '99CLUB_DOCKER_STUDY'
              ? '알림 신청'
              : badgeText
          }
        >
          {is99Club(courseType)
            ? '모집 중'
            : // @ts-ignore
            courseType === '99CLUB_DOCKER_STUDY'
            ? '알림 신청'
            : badgeText}
        </S.RoundInfoBadge>
      </Flex.Row>
    </LoggingClick>
  );
}
