import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  client: {
    NEXT_PUBLIC_AMPLITUDE_KEY: z.string(),
    NEXT_PUBLIC_AWS_ACCESS_KEY_ID: z.string(),
    NEXT_PUBLIC_AWS_S3_REGION_NAME: z.string(),
    NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY: z.string(),
    NEXT_PUBLIC_AWS_STORAGE_BUCKET_NAME: z.string(),
    NEXT_PUBLIC_AWS_STORAGE_CDN_BUCKET: z.string(),
    NEXT_PUBLIC_CDN_BASE_URL: z.string().url(),
    NEXT_PUBLIC_FLOUNDER_KEY: z.string(),
    NEXT_PUBLIC_HACKLE_SDK_KEY: z.string(),
    NEXT_PUBLIC_NBC_API_URL: z.string().url(),
    NEXT_PUBLIC_ONLINE_API_URL: z.string().url(),
    NEXT_PUBLIC_ONLINE_URL: z.string().url(),
    NEXT_PUBLIC_PAGE_URL: z.string().url(),
    NEXT_PUBLIC_SERVER: z.string().url(),
  },
  runtimeEnv: {
    NEXT_PUBLIC_AMPLITUDE_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
    NEXT_PUBLIC_AWS_ACCESS_KEY_ID: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY_ID,
    NEXT_PUBLIC_AWS_S3_REGION_NAME: process.env.NEXT_PUBLIC_AWS_S3_REGION_NAME,
    NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY:
      process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY,
    NEXT_PUBLIC_AWS_STORAGE_BUCKET_NAME:
      process.env.NEXT_PUBLIC_AWS_STORAGE_BUCKET_NAME,
    NEXT_PUBLIC_AWS_STORAGE_CDN_BUCKET:
      process.env.NEXT_PUBLIC_AWS_STORAGE_CDN_BUCKET,
    NEXT_PUBLIC_CDN_BASE_URL: process.env.NEXT_PUBLIC_CDN_BASE_URL,
    NEXT_PUBLIC_FLOUNDER_KEY: process.env.NEXT_PUBLIC_FLOUNDER_KEY,
    NEXT_PUBLIC_HACKLE_SDK_KEY: process.env.NEXT_PUBLIC_HACKLE_SDK_KEY,
    NEXT_PUBLIC_NBC_API_URL: process.env.NEXT_PUBLIC_NBC_API_URL,
    NEXT_PUBLIC_ONLINE_API_URL: process.env.NEXT_PUBLIC_ONLINE_API_URL,
    NEXT_PUBLIC_ONLINE_URL: process.env.NEXT_PUBLIC_ONLINE_URL,
    NEXT_PUBLIC_PAGE_URL: process.env.NEXT_PUBLIC_PAGE_URL,
    NEXT_PUBLIC_SERVER: process.env.NEXT_PUBLIC_SERVER,
  },
});
